import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { CssBaseline, SnackbarProvider } from '@applift/factor'
import {
  AppProvider,
  AppRootProvider,
  DataDogProvider,
  AppRootProps,
  PlatformDevTools,
} from '@applift/platform'
import { attachIqmInstance as attachAppUtilInstance } from '@applift/app-utils'
import { AppReports } from '@applift/icons'
import { attachIqmInstance } from '../api/instance'
import { queryClient } from '../cache'
import '../registry/init'
import { AppRoute } from './AppRoute'

export const REACT_APP_ORIGIN_URL =
  process.env.REACT_APP_ORIGIN_URL ?? 'https://iqm.stage.applift.com'

const devAuthCredentials = {
  email: process.env.REACT_APP_EMAIL,
  password: process.env.REACT_APP_PASSWORD,
} as any

/* refer to package.json these envs are configured there */
export const REACT_APP_ENVIRONMENT = (process.env.REACT_APP_ENVIRONMENT ??
  'stage') as AppRootProps['deploymentEnvironment']
export const REACT_APP_VERSION = process.env.REACT_APP_VERSION ?? '-'
export const REACT_APP_DD_CLIENT_TOKEN =
  process.env.REACT_APP_DD_CLIENT_TOKEN ?? ''
export const REACT_APP_DD_APPLICATION_ID =
  process.env.REACT_APP_DD_APPLICATION_ID ?? ''

// We are setting here for quick change that would eleminate restarting the app when ORIGIN_URL changes
const REACT_APP_ORIGIN_URL_LOCALSTORAGE =
  window.localStorage.getItem('ORIGIN_URL')

export const App = () => {
  return (
    <AppRootProvider
      appLogo={<AppReports />}
      appName="Reports"
      appVersion={REACT_APP_VERSION.split('-')?.[0] as string}
      deploymentEnvironment={REACT_APP_ENVIRONMENT}
      originURLFallback={
        REACT_APP_ORIGIN_URL_LOCALSTORAGE || REACT_APP_ORIGIN_URL
      }
      useNewCrossLoginURL={true}
      isAppBeta={false}
      legacyMode={true}
      attachInterceptorsToInstance={instance => {
        attachIqmInstance(instance)
        attachAppUtilInstance(instance)
      }}
      dataDogCredentials={{
        clientToken: REACT_APP_DD_CLIENT_TOKEN,
        applicationId: REACT_APP_DD_APPLICATION_ID,
      }}
    >
      <DataDogProvider>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider domRoot={document.body}>
            <CssBaseline />
            {/*@ts-ignore */}
            <AppProvider>
              <AppRoute />
            </AppProvider>
          </SnackbarProvider>
          <ReactQueryDevtools
            initialIsOpen={false}
            panelPosition="right"
            position="top-right"
          />
          <PlatformDevTools
            position="bottom-left"
            panelPosition="left"
            slotProps={{
              authProps: {
                initData: devAuthCredentials,
              },
            }}
          />
        </QueryClientProvider>
      </DataDogProvider>
    </AppRootProvider>
  )
}
